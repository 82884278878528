import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_05.webp'
import Metro06 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_06.webp'
import Metro07 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_07.webp'
import Metro08 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_08.webp'
import Metro09 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Multi_09.webp'


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="The Multiview window is the default menu that shows up when you open the app. It shows you all your cameras with a low refresh rate to preserve bandwidth."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="You can find two more buttons next to the Plus Button in the top right corner. Clicking the Snooze Button will deactivate the alarm on all your cameras until reactivate it by clicking it again. The second button allows you to multi select ..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="... several cameras by clicking on the box symbol in the top right corner of each camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Once you selected all cameras you need, click on the Arrow Button in the top right to open the command menu."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Now select the command that you want to send to all of the selected cameras."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Clicking on a camera without multi-select active will open this camera&apos;s live video stream. Note the indicator on top of the video..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="... it allows you to switch between control modes. Show no controls, show all controls ..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="... or the swipe mode that allows you to pan and tilt your camera by swiping across the video area."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Clicking on the cog wheel icon opens up your camera settings."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function MultiviewGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}

export default MultiviewGallery